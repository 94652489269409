import classNames from 'classnames';
import React from 'react';
import Iframe from 'react-iframe';
import Style from 'styles/components/comachicart/customers_voice.module.scss'

import {cese_study_list} from '../../constants';

const CustomersVoice = () => {
  return (
    <section className={Style.customersVoice}>
      <h2 className={classNames(Style.catch)}>
        <span>お客様の声</span>
      </h2>
      <div className={Style.caseStudyList}>
      <Iframe
  src =
      {cese_study_list}
        className={Style.caseStudyList__html}
          title='case-study'
        ></Iframe>
        </div>
    </section>
  );
    }

export default CustomersVoice;